<template>
  <div class="transportation-accounts">
    <div v-if="$router.currentRoute.name === 'transportation-accounts' && loadedAllRequiredAssets">
      <br />
      <h1 class="text-xs-center">Transportation Accounts</h1>
      <v-layout row wrap>
        <v-flex md8 sm6 pa-2>
          <form @submit.prevent="searchAccounts">
            <v-text-field
              solo
              v-model="searchKeywords"
              append-icon="fal fa-search"
              :rules="[v => !v || v.length >= 2 || 'Min 2 characters']"
              :disabled="apiInProgress"
              type="search"
              placeholder="Search by company, name, email, phone, licence plate"
              @click:append="searchAccounts"
            />
          </form>
        </v-flex>
        <v-flex md2 sm3 pa-2>
          <v-select
            :items="countries"
            label="Country"
            :value="selectedCountry"
            @change="onFilterByCountry"
          />
        </v-flex>
        <v-flex md2 sm3 pa-2>
          <v-btn block color="primary" v-if="hasUserType('admin')" :disabled="apiInProgress" :to="{ name: 'transportation-account', params: {accountId: 0} }"><v-icon>fal fa-plus</v-icon> &nbsp; Create Account</v-btn>
        </v-flex>
      </v-layout>
      <v-tabs icons-and-text @change="onTabChanged">
        <v-tabs-slider color="primary" />
        <v-tab ripple href="#accounts">
          Accounts {{selectedAccountTab !== 'accounts' ? `(${totalAccounts})` : ''}}
          <v-icon>fal fa-user</v-icon>
        </v-tab>
        <v-tab ripple href="#archived-accounts" v-if="hasUserType('admin')">
          Archived Accounts {{selectedAccountTab !== 'archived-accounts' ? `(${totalArchivedAccounts})` : ''}}
          <v-icon>fal fa-user-times</v-icon>
        </v-tab>
        <v-tabs-items v-model="selectedAccountTab">
          <v-tab-item value="accounts">
            <v-data-table
              v-if="selectedAccountTab === 'accounts'"
              :headers="headers"
              :items="accounts"
              :pagination.sync="pagination"
              :total-items="getTotal(selectedAccountTab)"
              :loading="apiInProgress"
              :rows-per-page-items="[10, 25, 50]"
              @update:pagination="onPagination"
              class="elevation-1"
              must-sort
            >
              <template v-slot:items="props">
                <tr :class="props.item.isEditable ? 'tr--pointer' : null" @click="loadAccount(props.item)">
                  <td :title="props.item.companyName">
                    <v-text-field
                      class="text-xs-left text-field--flat"
                      :value="props.item.companyName"
                      readonly
                      persistent-hint
                      :hint="`${props.item.transportationOwnerProfiles.length} Vehicle(s)`"
                    />
                  </td>
                  <td :title="props.item.contactName">
                    <v-text-field
                      class="text-xs-left text-field--flat"
                      :value="props.item.contactName"
                      readonly
                      hide-details
                    />
                  </td>
                  <td class="text-xs-left">{{ props.item.contactPhone }}</td>
                  <td class="text-xs-left" :title="props.item.contactEmail">
                    <v-text-field
                      class="text-xs-left text-field--flat"
                      :value="props.item.contactEmail"
                      readonly
                      hide-details
                    />
                  </td>
                  <td class="text-xs-left">{{ props.item.currentCountryLabel }}</td>
                  <td class="text-xs-left">{{ props.item.dateCreatedLabel }}</td>
                  <td class="text-xs-left">{{ props.item.dateUpdatedLabel }}</td>
                  <td class="justify-center layout px-0">
                    <v-btn flat icon v-if="!props.item.isSelectedAccount" title="Set as selected account" @click.stop="setAsSelectedAccount(props.item)">
                      <v-icon small>
                        fas fa-bullseye-pointer
                      </v-icon>
                    </v-btn>
                    <v-btn flat icon class="warning" v-if="props.item.isSelectedAccount" title="Clear selected account" @click.stop="clearSelectedAccount()">
                    <v-icon small>
                      fas fa-bullseye
                    </v-icon>
                  </v-btn> &nbsp; <v-btn flat icon title="Archive account" :disabled="!props.item.isEditable || props.item.isSelectedAccount" :loading="props.item.isArchiveInProgress"  @click.stop="archiveAccount(props.item)">
                    <v-icon small>
                      fas fa-archive
                    </v-icon>
                  </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item value="archived-accounts" v-if="hasUserType('admin')">
            <v-data-table
                v-if="selectedAccountTab === 'archived-accounts'"
                :headers="archivedHeaders"
                :items="accounts"
                :pagination.sync="pagination"
                :total-items="getTotal(selectedAccountTab)"
                :loading="apiInProgress"
                :rows-per-page-items="[10, 25, 50]"
                @update:pagination="onPagination"
                class="elevation-1"
                must-sort
            >
              <template v-slot:items="props">
                <tr>
                  <td :title="props.item.companyName">
                    <v-text-field
                      class="text-xs-left text-field--flat"
                      :value="props.item.companyName"
                      readonly
                      persistent-hint
                      :hint="`${props.item.transportationOwnerProfiles.length} Vehicle(s)`"
                    />
                  </td>
                  <td :title="props.item.contactName">
                    <v-text-field
                      class="text-xs-left text-field--flat"
                      :value="props.item.contactName"
                      readonly
                      hide-details
                    />
                  </td>
                  <td class="text-xs-left">{{ props.item.contactPhone }}</td>
                  <td class="text-xs-left" :title="props.item.contactEmail">
                    <v-text-field
                      class="text-xs-left text-field--flat"
                      :value="props.item.contactEmail"
                      readonly
                      hide-details
                    />
                  </td>
                  <td class="text-xs-left">{{ props.item.dateCreatedLabel }}</td>
                  <td class="text-xs-left">{{ props.item.dateArchivedLabel }}</td>
                  <td class="justify-center layout px-0">
                    <v-btn flat icon :loading="props.item.isArchiveInProgress" @click.stop="undoArchiveAccount(props.item)">
                      <v-icon small>
                        fas fa-undo
                      </v-icon>
                    </v-btn> &nbsp; <v-btn flat icon @click.stop="deleteAccount(props.item)">
                    <v-icon small>
                      fas fa-trash-alt
                    </v-icon>
                  </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>
    <router-view
      v-if="loadedAllRequiredAssets"
      :force-root-view-refresh="forceRootViewRefresh"
      :signed-in-user="signedInUser"
      :selected-account="selectedAccount"
      :set-as-selected-account="setAsSelectedAccount"
      :has-user-type="hasUserType"
      :has-transportation-role="hasTransportationRole">
    </router-view>
  </div>
</template>

<script>
import src from './src'
export default src
</script>
